@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@600;700&display=swap');
.heading-1 {
    font-size: 50px;
    font-weight: bolder;
}

.con1 {
    margin-top: 48px;
    height: 71px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.con2 {
    margin-top: 48px;
    height: 71px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
p{
    font-family: 'Outfit';
    font-weight: 600;
}
.after-img{
    font-family: 'Outfit';
    font-weight: 500;
    margin-left: 8px;
}

.src1, .src2, .src3, .src4 {
    display: flex;
    align-items:flex-start;
}
.sub-head{
    font-family: 'Outfit';
    font-weight: 600;
    margin-top: 10px;
}
.head1 {
    margin-top: 50px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.head2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
}
@media (max-width:964px) {
    .head2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0px;
    }
    .con1{
        margin-left: -40px;
    }
    .con2{
        margin-top: -8px;
    }

}
@media (max-width:689px) {
  .sub-head{
    text-align: center;
  }
  .heading-1{
    text-align: center;
  }
}
@media (max-width:503px) {
    .head1 {
        margin-top: 50px;
        margin-left: 5px;
    }
   .after-img{
    text-align: left;
   }
  }
  @media (max-width:459px) {

       .con2{
        margin-left: 10px;
       }
    }
  @media (max-width:425px) {
    .con1{
        margin-left: 10px;
    }

  }

@media (max-width:403px) {
    .con2 {
        margin-top: 16px;
    }

  }