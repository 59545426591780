.nav {
  position: sticky;
  top: 0px;
  z-index: 100;
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.25);
}

header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 80px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
}
.logo-2{
  display: none;
}
#buty{
  margin-right: 30px;
}



.logo {
  width: 150px;
  transition: 0.3s;
}

.logo:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.a {
  margin: 35px;
  color: var(--textColor);
  text-decoration: none;
  font-family: "outfit";
  font-weight: 600;
  font-size: 21px;
}

nav a:hover {
  color: #50c4fc;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  color: var(--textColor);
  opacity: 0;
  font-size: 1.8rem;
  display: none;
}

header div,
nav {
  display: flex;
  align-items: center;
}

@media (max-width: 1301px) {
  .a {
    margin: 29px;
    color: var(--textColor);
    text-decoration: none;
    font-family: "outfit";
    font-weight: bold;
    font-size: 19px;
  }
}

@media (max-width: 1187px) {
  .a {
    margin: 19px;
    color: var(--textColor);
    text-decoration: none;
    font-family: "outfit";
    font-weight: bold;
    font-size: 19px;
    display: flex;
    gap: -4px;
  }
}

@media (max-width: 1099px) {
  .a {
    margin: 11px;
    color: var(--textColor);
    text-decoration: none;
    font-family: "outfit";
    font-weight: bold;
    font-size: 19px;
    display: flex;
    gap: -4px;
  }
}

@media (max-width: 1024px) {
  #buty {
    margin-bottom: 10px;
    margin-right: 0px;
    margin-top: 20px;
}
.logo-2 {
  display: inline;
  margin-bottom: 64px;
}
  .btn2 {
    width: 126px;
    background-color: #51c3fe;
    font-size: 15px;
    font-weight: bold;
    font-family: "outfit";
    border-radius: 11px;
    height: 46px;
    transition: color 0.5s, background-color 0.5s, transform 0.5s;
    position: relative;
    border: none;
}

  .nav {
    border-bottom: 1px solid #e7e7e7;
  }

  header .nav-btn {
    padding: 5px;
    cursor: pointer;
    border: none;
    color: var(--textColor);
    font-size: 1.8rem;
    display: inline;
    opacity: 1;
    /* Ensure the icon is fully visible */
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: -20px;
    background-color: var(--mainColor);
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .a {
    font-size: 25px;
  }
  img.logo {
    width: 150px;
    transition: 0.3s;
    margin-right: 100px;
}

svg {
    color: #50c4fc;
    height: 72px;
    width: 31px;
  }

  header {
    justify-content: space-between;
  }
}

@media (max-height: 652px) {
  .a {
    margin: 22px;
    color: var(--textColor);
    text-decoration: none;
    font-family: "outfit";
    font-weight: bold;
    font-size: 22px;
  }
}

@media (max-height: 541px) {
  .a {
    margin: 16px;
    color: var(--textColor);
    text-decoration: none;
    font-family: "outfit";
    font-weight: bold;
    font-size: 19px;
  }
}

@media (max-height: 450px) {
  .a {
    margin: 11px;
    color: var(--textColor);
    text-decoration: none;
    font-family: "outfit";
    font-weight: bold;
    font-size: 15px;
  }
}