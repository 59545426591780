@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@600;700&display=swap');
/* .card-title4 {
    display: flex;
    align-items: start;
    gap: 16px;
  } */


h1 {
    font-family: 'outfit';

}


code {
    font-family: 'outfit';
    color: #50C4FC;

}

a {
    text-decoration: none;
    color: black;
}

.btns {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
}

.main-image {

    color: #51c3fd;
    animation-duration: 1s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        margin-left: 10%;
    }

    to {
        margin-left: 0%;
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center;
    text-align: center; */
}

.main-content {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
}

.content h1 {
    font-size: 59px;
    margin-top: 57px;
    font-weight: bolder;
    /* text-align: left; */
}

.content p {
    font-family: 'Outfit';
    font-size: 21px;
    font-weight: 500;
    margin-top: 20px;
    /* text-align: left; */
}

@media (max-width:1457px) {
    .main-image {
        height: 444px;
    }

    .content h1 {
        font-size: 51px;
        margin-top: 57px;
        font-weight: bolder;
    }
}

@media (max-width:1280px) {
    .main-image {
        height: 449px;
    }

    .content h1 {
        font-size: 48px;
        margin-top: 57px;
        font-weight: bolder;
        line-height: 49px;
    }

    .content p {
        font-family: 'Outfit';
        font-size: 19px;
        font-weight: bold;
        margin-top: 20px;
        line-height: 22px;
    }
}

@media (max-width:1169px) {
    .content p {
        font-family: 'Outfit';
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        line-height: 22px;
    }

    .content h1 {
        font-size: 40px;
        margin-top: 30px;
        font-weight: bolder;
        line-height: 49px;
    }
}

@media (max-width:1154px) {
    .main-image {
        height: 374px;
    }

    .main-content {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        padding-right: 40px;
        padding-left: 40px;
        margin-top: 52px;
    }
}

@media (max-width:1017px) {
    .content h1 {
        font-size: 35px;
    }
}

@media (max-width:1076px) {
    img.main-image {
        height: 395px;
    }

    .main-content {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        padding-right: 40px;
        padding-left: 40px;
        margin-top: 35px;
    }
}

@media (max-width:1010px) {
    img.main-image {
        height: 356px;
    }

    .content h1 {
        font-size: 34px;
        margin-top: 37px;
        font-weight: bolder;
        line-height: 49px;
    }

    .main-content {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        padding-right: 40px;
        padding-left: 40px;
        margin-top: -30px;
    }
}

@media (max-width:985px) {
    .main-image {
        height: 322px;
    }

    .content h1 {
        font-size: 35px;
        margin-top: 21px;
        font-weight: bolder;
        line-height: 40px;
    }

    .content p {
        font-family: 'Outfit';
        font-size: 14px;
        font-weight: bold;
        margin-top: 20px;
        line-height: 20px;
    }
}

@media (max-width:913px) {
    img.main-image {
        height: 336px;
    }
}

@media (max-width:887px) {
    .content h1 {
        font-size: 31px;
        margin-top: 21px;
        font-weight: bolder;
        line-height: 44px;
    }
}

#bt {
    margin-left: 0px;
}

@media (max-width:857px) {
    img.main-image {
        height: 100%;
    }

    .main-content {
        flex-direction: column;
        margin-top: 0px;
    }

    .btns {
        flex-direction: column;
    }

    #bt {
        margin-bottom: 10px;
        margin-left: 30px;
    }

    #bit {
        width: 160px;
        margin-right: 0px;
    }

    #bit::after {
        width: 154px;
        margin-right: 0px;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .content h1 {
        font-size: 50px;
        margin-top: 21px;
        font-weight: bolder;
        line-height: 50px;
    }


}

@media (max-width:570px) {
    .content h1 {
        font-size: 40px;
        margin-top: 21px;
        font-weight: bolder;
        line-height: 45px;
    }
}

@media (max-width:473px) {
    .content h1 {
        font-size: 34px;
        margin-top: 21px;
        font-weight: bolder;
        line-height: 41px;
    }
}