.radiobtn{
   display: flex;
   column-gap: 6px;
}

.container {
   height: 100vh;
 }
 
 .g-sign-in-button {
   position: relative;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   display: inline-block;
   width: 240px;
   height: 50px;
   background-color: #51c3fe;
   color: #fff;
   border-radius: 5px;
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
   transition: background-color .218s, border-color .218s, box-shadow .218s;
 }
 
 .g-sign-in-button:hover {
   cursor: pointer;
   -webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, .3);
   box-shadow: 0 0 3px 3px rgba(66, 133, 244, .3);
 }
 
 .g-sign-in-button:active {
   background-color: #3367D6;
   transition: background-color .2s;
 }
 
 .g-sign-in-button .content-wrapper {
   height: 100%;
   width: 100%;
   border: 1px solid transparent;
 }
 
 .g-sign-in-button img {
   width: 18px;
   height: 18px;
 }
 
 .g-sign-in-button .logo-wrapper {
   padding: 15px;
   background: #fff;
   width: 48px;
   height: 100%;
   border-radius: 5px;
   display: inline-block;
 }
 
 .g-sign-in-button .text-container {
   font-family: "Roboto", arial, sans-serif;
   font-weight: 500;
   letter-spacing: .21px;
   font-size: 16px;
   line-height: 48px;
   vertical-align: top;
   border: none;
   display: inline-block;
   text-align: center;
   width: 180px;
 }














.select-menu{
    width: 380px;
    margin: 140px auto;
}
.select-menu .select-btn{
    display: flex;
    height: 55px;
    background: #fff;
    padding: 20px;
    font-size: 18px;
    font-weight: 400;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
}
.select-btn i{
    font-size: 25px;
    transition: 0.3s;
}
.select-menu.active .select-btn i{
    transform: rotate(-180deg);
}
.select-menu .options{
    position: relative;
    padding: 20px;
    margin-top: 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    display: none;
}
.select-menu.active .options{
    display: block;
}
.options .option{
    display: flex;
    height: 55px;
    cursor: pointer;
    padding: 0 16px;
    border-radius: 8px;
    align-items: center;
    background: #fff;
}
.options .option:hover{
    background: #F2F2F2;
}
.option i{
    font-size: 25px;
    margin-right: 12px;
}
.option .option-text{
    font-size: 18px;
    color: #333;
}