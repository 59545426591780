@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap');

.footer {
    background-color: #333333;
    overflow: hidden;
}
.svg{
    width: 19px;
    height: 9px;
}
footer{
    display: flex;
    align-items:flex-start;
    justify-content: space-evenly;
}
.pays {
    color: white;
    font-family: 'Outfit';
    font-size: 29px;
}
.payments {
    margin-top: 56px;
}
.languages {
    display: flex;
    align-items: center;
    margin-top: 56px;
    position: relative;
}
.uz-opt {
    background-color: white;
    height: 100px;
    width: 101px;
    position: absolute;
    top: 30px;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    transition-duration: 1s;
    box-shadow: inset 0 6px 10px -4px #ccc, 0 6px 10px -4px #ccc;
    overflow: hidden;
}
.l1{
    width: 100%;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.l1:hover{
    background-color: #b1b1b1;
    transition-duration: .5s;
    border-radius: 10px;
    
}
.paypic {
    height: 30px;
    margin-top: 10px;
    margin-bottom: 4px;
}
.nexsms{
    color: #50C4FC;
    font-family: 'Outfit';
    margin-bottom: 10px;
}
.resources{
    color: #FFF27D;
    font-family: 'Outfit';
    margin-bottom: 10px;
}
.features{
    color: #D57BFF;
    font-family: 'Outfit';
    margin-bottom: 10px;
}
.languageicon {
    height: 27px;
}
img.vector {
    height: 7px;
}
.languages{
    display: flex;
    align-items: flex-start;
}
.link1-list,.link2-list, .link3-list{
   list-style: none;
   color: white;
   font-family: 'outfit';
   cursor: pointer;
}
.link1, .link2, .link3{
    margin-top: 56px;
 }
.link3-list li{
    padding: 10px 10px;
 }
 .link2-list li{
    padding: 10px 10px;
 }
 .link1-list li{
    padding: 10px 10px;
 }
 .link3-list li:hover{
    color: #D57BFF;
 }
 .link2-list li:hover{
    color:#FFF27D;
 }
 .link1-list li:hover{
    color:#50C4FC;
 }
 .footerend-h {
    color: white;
    font-family: 'outfit';
    margin-left: 50px;
    font-size: 18px;
    font-weight: 100;
}
.facebook, .twitter, .linkedin, .youtube, .insta{
    height: 25px;
    margin-left: 15px;
}
.insta{
    margin-right: 50px;
}
.insta:hover{
    transform: scale(1.2);
    transition-duration: 0.2s;
}
.youtube:hover{
    transform: scale(1.2);
    transition-duration: 0.2s;

}
.twitter:hover{
    transform: scale(1.2);
    transition-duration: 0.2s;
}
.linkedin:hover{
    transform: scale(1.2);
    transition-duration: 0.2s;
}
.facebook:hover{
    transform: scale(1.2);
    transition-duration: 0.2s;
}

.footer-end {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 55px;
    padding-bottom: 16px;
}
.spn1:hover{
    color: #50C4FC;
    cursor: pointer;
}
.bold{
    font-weight: bolder;
}
select#select {
    margin-left: 10px;
    background-color: white;
    border: none;
    border-radius: 13px;
    width: 114px;
    height: 31px;
    font-family: 'Outfit';
    font-weight: bolder;
    padding-left: 9px;

}

@media (max-width:926px) {
    .pays {
        color: white;
        font-family: 'Outfit';
        font-size: 21px;
    }
}
@media (max-width:822px) {
    footer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
    }
    .pays {
        color: white;
        font-family: 'Outfit';
        font-size: 39px;
        
    }
    .payments{
        text-align: center;
    }
    .nexsms {
        font-size: 29px;
    }
    .resources {
        font-size: 29px;
    }
    .features {
        font-size: 29px;
    }
    .link1-list, .link2-list, .link3-list {
        list-style: none;
        color: white;
        font-family: 'outfit';
        cursor: pointer;
        font-size: 22px;
        text-align: center;
    }
}
@media (max-width:738px) {
    .footer-end {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 55px;
        padding-bottom: 16px;
        flex-direction: column;
    }
    .insta {
        margin-right: 0px;
    }
    .socialicons{
        margin-top: 10px;
    }
}
@media (max-width:524px) {
    .footerend-h {
        margin-left: 0px;
        text-align: center;
    }
}
@media (max-width:374px) {
    .pays {
        color: white;
        font-family: 'Outfit';
        font-size: 29px;
    }
}