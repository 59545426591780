.in_box2 {
    width: 45%;
    height: 74%;
    border: 2px solid black;
    border-radius: 16px;
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    flex-direction: column;
    flex-shrink: 0;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

#box2 {
    width: 80%;
    height: calc(100vh - 230px);
    background: white;
    margin: auto;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fill1 {
    display: flex;
    width: 34%;
    align-items: center;
    justify-content: space-between;
}

.in_box {
    width: 45%;
    height: 34%;
    border: 2px solid black;
    border-radius: 16px;
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    flex-direction: column;
    flex-shrink: 0;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.fill2 {
    display: flex;
    width: 34%;
    align-items: center;
    justify-content: space-between;
}


.btns {
    width: 45%;
    /* margin-right: auto; */
    /* margin-left: auto; */
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.fill3 {
    display: flex;
    width: 19%;
    align-items: center;
    justify-content: space-between;
}

#inp {
    width: 114px;
    padding-top: 5px;
}

.left {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    padding-left: 30px;
}

.right {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

}

.checkoutcontainer {
    z-index: 0;
}