.priceheading {

    justify-content: center;
    display: flex;
    margin-top: 50px;
    font-size: 30px;
}

.pricesubheading {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    font-size: 20px;

}

@media(max-width: 1269px) {
    .priceheading {

        justify-content: center;
        display: flex;
        margin-top: 50px;
        font-size: 18px;
    }

    .pricesubheading {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        font-size: 15px;

    }
}