#but {
    justify-content: center;
    margin-right: 30px;
}

.bt2 {
    margin-left: 10px;
}

.bt1 {
    margin-right: 10px;
}


@media (max-width:490px) {
    .bt2 {
        margin-left: 19px;
        margin-top: 10px;
    }

    .bt1 {
        margin-right: -14px;
        margin-bottom: 10px;
    }

    .main {
        padding: 0px 50px;
    }
}