@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@600;700&display=swap");

:root {
  --mainColor: white;
  --mainColorLight: #5767aa;
  --secondaryColor: #5767aa;
  --textColor: black;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.btn1 {
  width: 126px;
  background-color: white;
  height: 46px;
  font-size: 15px;
  font-weight: bold;
  font-family: "outfit";
  border: 3px solid black;
  border-radius: 13px;

  transition: color 0.5s, background-color 0.5s, transform 0.5s;
}

.btn2 {
  width: 126px;
  background-color: #51c3fe;
  font-size: 15px;
  font-weight: bold;
  font-family: "outfit";
  border-radius: 11px;
  height: 46px;
  transition: color 0.5s, background-color 0.5s, transform 0.5s;
  position: relative;
  border: none;

}

.mbtn {
  width: 262px;
  background-color: #51c3fe;
  font-size: 15px;
  font-weight: bold;
  font-family: "outfit";
  border-radius: 11px;
  height: 56px;
  transition: color 0.5s, background-color 0.5s, transform 0.5s;
  position: relative;
  border: none;

}

.mbtn::after {
  transition-duration: 0.5s;
  content: "";
  position: absolute;
  top: -6px;
  left: -8px;
  border: 3px solid black;
  height: 52px;
  width: 250px;
  border-radius: 13px;
}

.mbtn:hover::after {
  transform: scale(1.05);
  top: 0px;
  left: 3px;
  background: transparent;

}


button.btn1:hover {
  color: white;
  background-color: black;
  transition-duration: 0.5s;
  transform: scale(1.05);
}

.btn2::after {
  transition-duration: 0.5s;
  content: "";
  position: absolute;
  top: -6px;
  left: -8px;
  border: 3px solid black;
  height: 42px;
  width: 120px;
  border-radius: 13px;
}

.btn2:hover::after {
  transform: scale(1.05);
  top: 0px;
  left: 0px;
  background: transparent;
}

.error {
  border: 1px solid #ff5d5d !important;
}