@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');

.uheading {
    margin: 10px 0px 0px 300px;
}

.top {
    display: flex;
    gap: 300px;
    margin: 0px 0px 0px 0px;
}

.btns1 {
    display: flex;
    margin-top: 100px;
    gap: 25px;
}

.mpurchasebtnmm {
    margin-left: 30px;
    height: 40px;
    width: 157px;
    font-family: "outfit";
    background-color: #ABABAB;
    color: white;
    font-weight: bolder;
    border: 2px solid black;
    font-size: 15px;
    border-radius: 10px;
    transition: ease-out 0.5s;
    outline: none;
    box-shadow: inset 0 0 0 0 black;


}

.mpurchasebtnmm:hover {
    box-shadow: inset 300px 0 0 0 rgb(119, 129, 143);
    cursor: pointer;
}

.mpurchasebtn {
    height: 45px;
    width: 147px;
    font-family: "outfit";
    background-color: #51c3fe;

    font-weight: bolder;
    border: 2px solid;
    font-size: 15px;
    border-radius: 12px;
    transition: ease-out 0.5s;
    outline: none;
    box-shadow: inset 0 0 0 0 #51c3fe;
}

.mpurchasebtn:hover {
    box-shadow: inset 300px 0 0 0 rgb(30, 120, 238);
    cursor: pointer;

}

.mpurchasebtnm {
    height: 45px;
    width: 167px;
    font-family: "outfit";
    background-color: #4169E1;
    color: white;
    font-weight: 100px;
    border: 2px solid black;
    font-size: 15px;
    border-radius: 22px;
    transition: ease-out 0.5s;
    outline: none;
    box-shadow: inset 0 0 0 0 blue;
}

.mpurchasebtnm:hover {
    box-shadow: inset 300px 0 0 0 rgb(30, 120, 238);
    cursor: pointer;

}

.outer-container {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 20px 50px;

    background-color: #F2F2FD;

}

.souter-container {

    display: flex;
    flex-direction: column;
    height: auto;
    padding: 20px 50px;
    margin-bottom: 100px;

    align-items: center;
}

.mcard {
    width: 600px;
    border: 3px solid black;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    margin-top: 20px;
    transition: 0.3s;
    display: flex;
    background-color: white;
    width: calc(45% - 20px);
}

.mcard:hover {
    border: 3px solid #87CEEB;
    transform: scale(1.01);
}

.all-content {
    display: flex;
    gap: 200px;
}


.mcard-content {
    width: auto;
    display: flex;
    margin: 0px 0px 0px 30px;
}

.mcard-title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 8px;
}

.mcard-description {
    font-size: 16px;
    color: #444;
    margin: 20px;

}

.list-description {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.price-section {
    display: flex;
    flex-direction: column;
    gap: 55px;

}


.price {

    display: flex;
    font-weight: 700px;
    font-size: 50px;
    margin: 20px 0px 0px 0px;
}



.mpurchasebtn {
    background-color: #4169E1;
    color: white;
    width: 150px;
    height: 40px;
    border-radius: 8px;
    border: 2px solid black;
    font-family: Space Grotesk;
    margin: 0px 0px 0px 30px;
}