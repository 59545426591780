@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap");



body {
  font-family: Outfit;
}
.btn-cont {
    display: flex;
    align-items: center;
}
.btn-cont2 {
  margin-left: 10px;
}
.btn-cont1 {
  margin-right: 15px;
  margin-left: 30px;
}
.heading5 {
  padding: 100px 50px 0px 60px;
  font-size: 25px;
  text-align: center;

}
.rectangle:hover {
  cursor: pointer;
}
.rectangle {
  width: calc(75% - 20px);
  margin: 20px auto;
  background: #50c4fc;
  transition: 0.4s;
  border-radius: 15px;
  padding: 20px 0px 30px;
  position: relative;
  height: 194px;
}
.inner-rectangle {
  background: #0000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -20px;
  bottom: 0;
  left: -15px;
  right: 0;
  margin: auto;
  border-radius: 15px;
  z-index: 0;
  border: 3px solid;
  height: 200px;
}
.rectangle-heading {
  padding: 25px 50px;
  font-family: Outfit;
  font-size: 25px;
  font-weight: bold;
}
#uz-btn1{
  background-color: white;
}
#uz-btn2{
  background-color: #50c4fc;
}
#uz-btn2:hover{
  background-color: black;
}

.rectangle-heading-style {
  font-size: 30px;
  font-weight: bolder;
}
.button1 {
  background-color: #50c4fc;
  border-radius: 6px;
  padding: 8px 35px 8px 35px;
  margin-left: 50px;
  font-family: Outfit;
  border-width: 2px;
  font-weight: bold;
}

.middle {
  margin-left: 210px;
  margin-top: -25px;
  font-family: Outfit;
  padding-right: 5px;
  position: absolute;
}



.heading6 {
  font-size: 35px;
  font-weight: bold;
}

.heading3 {
  font-family: Outfit;
  font-size: 30px;
  margin-bottom: 5px;
}

.heading4 {
  font-family: Outfit;
  font-size: 18px;
  margin-top: 0px;
  font-weight: 100;
}

.container1 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  padding: 50px;
  margin-left: 200px;

  padding-top: 20px;
}

.container1 .card1 {
  position: relative;
  height: 400px;
  margin: 30px 10px;

  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in-out;
  margin-top: 8%;
}

.container1 .card1 .imgbx2 {
  position: relative;
  width: 340px;
  height: 350px;
}

.container1 .card1 .imgbx2 img {
  max-width: 100%;
  border-radius: 10px;
}
.imgbx2 img{
  transition:  transform 1s;
}

.imgbx2:hover img {
  transition-duration: 1s;
  transform: scale(1.05);
}


@media(max-width: 1640px) {
    .container1{
      margin-left: 0px;
    }
    .heading5{
      margin-left: 0px;
      margin-right: 0px;
    }
    .card-title4 {
      margin-left: 0px;
    }
}

@media(max-width: 1280px) {
  .container1 {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    margin-left: 0px;
  }
  .container1 .card1 {
    justify-content: center;
    flex-direction: unset;
    margin-left: 0px;
  }
  .heading5 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .card-title4 {
    display: hide;
  }

}

@media(max-width: 768px) {
  .container1 {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    margin-left: 0px;
  }

  .container1 .card1 {
    justify-content: center;
    flex-direction: unset;
  }
  .heading5 {
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .heading3 {
    text-align: center;
  }
  .heading4 {
    text-align: center;
  }
  .container1 .card1 .imgbx2 {
    position: relative;
    width: 340px;
    height: 350px;

  }
  .container1 {
    padding: 20px;
  } 
    
}
@media (max-width:1124px) {
  .rectangle-heading{
    font-size: 20px;
  }
}
@media  (max-width: 793px) {


  .rectangle {
    height: 280px;
  }
  .inner-rectangle {
    height: 287px;
    top: -27px;
    bottom: 0;
    left: -24px;
  }
  
  .button1 {
    padding-left: 100px;
    padding-right: 100px;
    margin-left: 110px;
  }
  .button2 {
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 40px;
    margin-left: 105px;
  }
  .middle {
    margin-left: 230px;
    margin-top: 10px;
  }
  .heading3 {
    text-align: center;
  }
  .heading4 {
    text-align: center;
  }
}
@media (max-width:590px) {
  .rectangle {
    height: 400px;
}
.inner-rectangle {
  height: 400px;
}
}
@media (max-width:490px) {
  .rectangle {
    height: 520px;
}
.inner-rectangle {
  height: 520px;
}
.btn-cont{
  flex-direction: column;
}
.rectangle-heading{
  padding-right: 0px;
}
}
@media (max-width:330px) {
 .rectangle-heading{
  font-size: 22px;
 }
}


