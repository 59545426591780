@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap");
.tagLine {
  text-align: center;
  font-family: Outfit;
  font-size: 20px;
}
h2 {
  font-size: 43px;
}
#whyus {
  margin-top: 50px;
}
.tagLine2 {
  text-align: center;
  font-size: 55px;
  font-family: Outfit;
}
code {
  color: #51c3fd;
}
.heading1 {
  font-size: 30px;
  margin-bottom: 5px;
  font-weight: bolder;
  font-family: "Outfit";
}
.heading2 {
  font-size: 18px;
  margin-top: 0px;
  font-weight: 100;
  font-family: "Outfit";
}
.container3 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.card-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}
.container3 .card {
  position: relative;
  height: 400px;
  margin: 30px 10px;

  display: flex;
  flex-direction: column;
  /* transition: 0.3s ease-in-out; */
  margin-top: 8%;
}
.img-box {
  width: 300px;
}
.img-box img {
  transition:  transform 1s;
}

.container3 .card .img-box img {
  max-width: 100%;
  border-radius: 10px;
}

.img-box img:hover {
  transform : scale(1.05);
  transition-duration: 1s;

}




@media (max-width: 1400px) {
  .container3 {
    flex-direction: column;
  }
  .card-container {
    justify-content: space-evenly;
  }

}
@media (max-width: 744px) {
  .card-container {
    flex-direction: column;
  }
  .card {
    display: flex;
    flex-direction: column;
    transition: 0.3s ease-in-out;
    margin-top: 8%;
    align-items: center;
    justify-content: center;
  }
  .img-box {
    /* width: fit-content;
    height: fit-content; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
