
.main-container {
    height: 254px;
    padding: 30px;
    background-color: #FdECFF;
    overflow: hidden;
}
.for-marquee  {
    padding: 0px 30px 0px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width:1266px) {
    img.logo1 {
        height: 60px;
    }
    img.logo2 {
        height: 80px;
    }
    img.logo3 {
        height: 69px;
    }
    img.logo4 {
        height: 107px;
    }
    img.logo5 {
        height: 65px;
    }
    .main-container{
        padding: 0px;
    }
    .for-marquee {
        padding: 0px 39px;
        margin-top: 30px;
    }
    h1.heading {
        margin-top: 37px;
        padding: 0px 20px 0px 20px;
    }
}
.for-marquee-after857 {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }

  .logo-after857 {
    animation: logoFadeAfter857 2s infinite;
    opacity: 0;
    display: none;
  }
  .logo-after857:first-child {
    opacity: 1;
    display: block;
  }
  @keyframes logoFadeAfter857 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
@media (max-width:1028px) {
    .for-marquee{
        margin-top: 0px;
    }
}
@media (max-width:1010px) {
    .main-container {
        padding: 0px;
        height: 278px;
    }
}
@media (max-width:913px) {
    h1.heading {
        margin-top: 77px;
    }
    .main-container {
        padding: 0px;
        height: 298px;
    }
}
@media (max-width:857px) {
    h1.heading {
        margin-top: 35px;
    }
    img.logo1{
        margin-top: 24px;
        height: 97px;
    }
    img.logo2 {
        height: 184px;
        margin-top: -7px;
    }
    img.logo3 {
        height: 159px;
    }
}
@media (max-width:572px) {
    h1.heading {
        margin-top: 18px;
    }
}
@media (max-width:413px) {
    h1.heading {
        font-size: 26px;
    }
}
@media (max-width:402px) {
    img.logo1 {
        margin-top: 24px;
        height: 85px;
    }
    
}
@media (max-width:397px) {
    h1.heading {
        margin-top: 18px;
        font-size: 26px;
    }
    
}
@media (max-width:374px) {
    h1.heading {
        margin-top: 18px;
        font-size: 26px;
    }
    img.logo1 {
        margin-top: 24px;
        height: 80px;
    }
}
@media (max-width:333px) {
    img.logo1 {
        margin-top: 24px;
        height: 64px;
    }
}







  
/* @media (max-width:751px) {
    .main-container{
        overflow-x: visible;
        height: fit-content;
    }
    .for-marquee {
        flex-direction: column;
        margin-top: 39px;
    }
    img.logo1 {
        height: 100px;
    }
    img.logo2 {
        height: 189px;
    }
    img.logo3 {
        height: 162px;
    }
    img.logo4 {
        height: 200px;
    }
    img.logo5 {
        height: 113px;
    }
}
@media (max-width:450px) {
    img.logo1 {
        height: 79px;
    }
}
@media (max-width:337px) {
    img.logo1 {
        height: 70px;
    }
} */