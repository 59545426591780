.featuresheading {
  margin-bottom: 100px;
  position: flex;
  text-align: center;
  font-size: 40px;
  font-weight: bolder;
  margin-top: 60px;
}

.rectangle3:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.inner-rectangle3:hover {
  transform: scale(1.02);
  border-color: #50C4FC;
  transition-duration: .4s;
}

.container2 {
  width: calc(63% - 15px);
  margin: 20px auto;
  background: #e3f5ff;
  transition: 0.4s;
  border-radius: 15px;
  padding: 20px 0px 20px;
  position: relative;
  height: 1121px;
  margin-top: 120px;
  /* display: flex; */
}

.inner-rectangle1 {
  background: #0000;
  width: 100%;
  height: 100%;
  position: relative;
  top: -37px;
  bottom: 0;
  left: -17px;
  right: 0;
  margin: auto;
  border-radius: 15px;
  z-index: 0;
  border: 3px solid;
  height: 1127px;
  margin-left: 10px;
}



.rectangle3 {
  /* width: calc(100% - 15px); */
  margin: 20px;
  background: white;
  transition: 0.4s;
  border-radius: 15px;
  /* padding: 50px 0px 30px; */
  position: relative;
  height: 160px;

  /* margin-left: 60px; */
  width: 90%;
  margin-left: 30px;
}

.inner-rectangle3 {
  background: #0000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -19px;
  bottom: 0;
  left: -25px;
  right: 0;
  margin: auto;
  border-radius: 15px;
  z-index: 0;
  border: 3px solid;
  height: 163px;
  transition: 0.5s;
}

.rectangle-heading3 {
  font-family: Outfit;
  font-size: 28px;
  font-weight: bold;
}

.card-title3 {
  display: flex;
  align-items: center;
  gap: 26px;
}

.card-wrapper-feature3 {
  padding: 25px 20px;
}

.card-subtitle3 {
  padding: 0px 50px 0px 90px;
  margin-top: -10px;

}

.card-subtitle3 p {
  font-size: 15px;

  font-family: Outfit;
  font-weight: 100;
}

.main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  padding: 20px 50px;
}

@media (min-width: 1132px) and (max-width: 1750px) {
  .container2 {
    width: 90%;
  }
}

@media (min-width: 1060px) and (max-width: 1132px) {
  .container2 {
    width: 95%;
  }
}

@media (min-width: 1025px) and (max-width: 1132px) {
  .container2 {
    /* width: 100%; */
    width: calc(100% - 15px);
  }

  .inner-rectangle1 {
    left: -5px;
  }
}

@media (min-width: 721px) and (max-width: 1025px) {
  .container2 {
    width: 85%;
    height: 1900px;
  }

  .inner-rectangle1 {
    height: 1900px;
  }

  .main {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .rectangle3 {
    width: 80%;

    margin-left: 70px;

  }
}

@media (min-width: 539px) and (max-width: 721px) {
  .container2 {
    width: 95%;
    height: 1900px;
  }

  .inner-rectangle1 {
    height: 1900px;
  }

  .main {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .rectangle3 {
    width: 100%;

    margin-left: 0px;

  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .container2 {
    width: 95%;
    height: 1900px;
  }

  .inner-rectangle1 {
    height: 1900px;
  }

  .main {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .rectangle3 {
    width: 115%;

    margin-left: -20px;

  }

  .rectangle-heading3 {
    font-size: 22px;
  }

  .card-subtitle3 {
    font-size: 16px;
  }
}

@media (min-width: 320px) and (max-width: 427px) {
  .container2 {
    width: 95%;
    height: 1900px;
  }

  .inner-rectangle1 {
    height: 1900px;
  }

  .main {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .rectangle3 {
    width: 125%;

    margin-left: -28px;

  }

  .rectangle-heading3 {
    font-size: 19px;
  }

  .card-subtitle3 {
    font-size: 13px;
  }
}

@media(max-width: 342px) {
  .container2 {
    width: 95%;
    height: 1900px;
  }

  .inner-rectangle1 {
    height: 1900px;
  }

  .main {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .rectangle3 {
    width: 135%;

    margin-left: -25px;

  }

  .rectangle-heading3 {
    font-size: 17px;
  }

  .card-subtitle3 {
    font-size: 11px;
  }

  .featuresheading {
    font-size: 25px;
    margin-bottom: 60px;
  }
}

@media (max-width: 388px) {
  .card-subtitle3 p {
    font-size: 13px;

  }

  .card-wrapper-feature3 {
    padding: 15px 20px;
  }
}