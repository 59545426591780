.parentform {
  width: 888px;
  height: 832px;
  flex-shrink: 0;
  border-radius: 25px;
  border: 1px solid #c7c7c7;
  background: #fff;
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.25);
  margin: auto;
  margin-top: 100px;
  display: flex;
  margin-bottom: 80px;
}

h5 {
  font-family: "outfit";
  display: inline;
  font-size: 12px;
  font-weight: lighter;
}


.msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #c7c7c7;
  width: 534px;
}

img.logo_demo_page {
  margin-top: 100px;
  height: 50px;
}

#heading {
  font-family: "outfit";
  margin-top: 100px;
}

#sub-head {
  font-family: "Outfit";
  font-weight: 500;
  margin-top: 20px;
}

p#question {
  font-size: 15px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

select.companysize {
  height: 37px;
  width: 417px;
  border-radius: 10px;
  border: 1px solid #838383;
  padding-left: 16px;
  font-weight: 500;
  font-size: 14px;

}

select.select-country {
  height: 37px;
  width: 417px;
  border-radius: 10px;
  border: 1px solid #838383;
  padding-left: 16px;
  font-weight: 500;
  font-size: 14px;

}

form {
  display: flex;
  align-items: center;
  justify-content: space-evenly !important;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.2s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.demotype,
.demotime,
.language,
.companysize,
.select-country {
  height: 37px;
  width: 417px;
  border-radius: 10px;
  border: 1px solid #838383;
  padding-left: 16px;
  cursor: pointer;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;

}

select.language {
  height: 37px;
  width: 417px;
  border-radius: 10px;
  border: 1px solid #838383;
  padding-left: 16px;
  font-weight: 500;
  font-size: 14px;
}

select.demotype {
  height: 37px;
  width: 417px;
  border-radius: 10px;
  border: 1px solid #838383;
  padding-left: 16px;
  font-weight: 500;
  font-size: 14px;
}

select.demotime {
  height: 37px;
  width: 417px;
  border-radius: 10px;
  border: 1px solid #838383;
  padding-left: 16px;
  font-weight: 500;
  font-size: 14px;
}

input.email {
  height: 37px;
  width: 417px;
  border-radius: 10px;
  border: 1px solid #838383;
  padding-left: 16px;
  font-weight: 500;
  font-size: 14px;
}

.react-tel-input .country-list .select-country {
  padding: 7px 9px;
  border: none;
  width: 100%;
  border-radius: 0px;
}

.my-custom-input {
  position: relative !important;
  font-size: 14px !important;
  letter-spacing: .01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #838383 !important;
  border-radius: 10px !important;
  line-height: 25px;
  height: 37px !important;
  width: 417px !important;
  outline: none;

}

.react-tel-input .country-list .select-country {
  padding: 7px 9px;
  border: none;
  width: 100%;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: white;
  border: 1px solid #838383;
}


.feild1 {
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: space-between;
}

.feild2 {
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: space-between;
}

.feild3 {
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: space-between;
}

.uz-f {
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: space-between;
}

.uz-2 {
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: space-between;
}

.feild4 {
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: space-between;
}

.feild5 {
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: space-between;
}

.feild6 {
  display: flex;
  flex-flow: column;
  align-items: center;
}

select.check1 {
  margin-right: 10px;
  margin-top: 14px;
}

.cm img {
  padding-right: 10px;
}

.submit-btn {
  height: 45px;
  width: 147px;
  font-family: "outfit";
  background-color: #51c3fe;
  color: black;
  font-weight: bolder;
  border: 2px solid;
  font-size: 18px;
  border-radius: 12px;
  transition: ease-out 1s;
  outline: none;
  box-shadow: inset 0 0 0 0 #51c3fe;
}

.submit-btn:hover {
  box-shadow: inset 300px 0 0 0 rgb(23, 149, 252);
  cursor: pointer;
  color: black
}



@media (max-width: 886px) {
  .parentform {
    flex-direction: column;
    height: 1240px;
    width: 500px;
  }

  .form {
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
  }

  .msg {
    border-right: 0px;
    border-bottom: 1px solid #c7c7c7;
    width: 100%;
  }

  .con {
    padding-bottom: 35px;
  }

  #heading {
    font-family: "outfit";
    margin-top: 30px;
  }

  img.logo_demo_page {
    margin-top: 35px;
    height: 50px;
  }
}

/* @media (max-width: 500px) {
  .parentform {
    flex-direction: column;
    height: 1240px;
    width: 371px;
  }
  input.email {
    width: 311px;
}
  select.companysize {
    width: 311px;
  }
  select.country {
    width: 311px;
  }
  select.language {
    width: 311px;
  }
  select.demotype {
    width: 311px;
  }
  select.demotime {
    width: 311px;
  }
} */
@media (max-width: 500px) {
  .parentform {
    width: 350px;
  }

  select.companysize {
    width: 250px;
  }

  select.select-country {
    width: 250px;
  }

  input.email {
    width: 250px;
  }

  select.language {
    width: 250px;
  }

  select.demotype {
    width: 250px;
  }

  select.demotime {
    width: 250px;
  }

  .react-tel-input .form-control {
    width: 250px !important;
  }
}