.mcontainerm {
    width: calc(100% - 15px);
    margin: 20px auto;
    background: white;
    transition: 0.4s;
    border-radius: 15px;
    padding: 20px 0px 20px;
    position: relative;
    height: auto;
    margin-top: 80px;
}

.mmain {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    padding: 20px 50px;
    margin-bottom: 100px;
}

.mrectangle {

    margin: 20px;
    background: #E0F4FF;
    transition: 0.4s;
    border-radius: 15px;
    position: relative;
    height: 550px;
    width: 90%;
    margin-left: 30px;
}

.minner-rectangle {
    background: #0000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -19px;
    bottom: 0;
    left: -25px;
    right: 0;
    margin: auto;
    border-radius: 15px;
    z-index: 0;
    border: 3px solid;
    height: 553px;
}

.mrectangle:hover {
    transform: scale(1.01);
    cursor: pointer;
}

.minner-rectangle:hover {
    border-color: #50C4FC;
    transition-duration: .4s;
}

.mrectangle-heading {
    font-family: Outfit;
    font-size: 35px;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.mcard-wrapper-feature {
    padding: 25px 20px;
}

.mcard-subtitle {
    margin-top: -10px;
    display: flex;
    justify-content: center;
}

.mcard-subtitle p {
    font-size: 22px;
    font-family: Outfit;
    margin-top: 20px;
    font-weight: 100;
}

.mbtn {
    width: 2202px;
}



.mcard-price {
    display: flex;
    justify-content: center;
    font-size: 30px;
    margin-top: 55px;
}

.mcard-period {
    display: flex;
    justify-content: center;
}

.mafter-img {
    font-family: 'Outfit';
    font-weight: 500;
}

.src1 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 40px;
}

.mplanbtn {
    display: flex;
    justify-content: center;
    margin-top: 80px;
}



@media(max-width: 1735px) {
    .mmain {
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
        margin-left: 0px;
    }

    .mmain .mcontainerm {
        justify-content: center;
        flex-direction: unset;
        margin-left: 0px;
    }

    .mrectangle-heading {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media(max-width: 900px) {
    .mmain {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        margin-left: 0px;
    }

    .mmain .mcontainerm {
        justify-content: center;
        flex-direction: unset;
    }
}

@media(max-width: 505px) {
    .mmain {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        margin-left: 0px;
    }

    .mmain .mcontainerm {
        justify-content: center;
        flex-direction: unset;
    }

    .overall {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(130% - 15px);
        margin-left: -40px;
    }
}

@media(max-width: 433px) {
    .mmain {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        margin-left: 0px;
    }

    .mmain .mcontainerm {
        justify-content: center;
        flex-direction: unset;
    }

    .overall {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(145% - 15px);
        margin-left: -60px;
    }

    .mcard-subtitle p {
        font-size: 17px;

        font-family: Outfit;
        margin-top: 20px;
        font-weight: 100;
    }

    .mafter-img {
        font-family: 'Outfit';
        font-weight: 500;
    }

    .mrectangle-heading {
        font-family: Outfit;
        font-size: 25px;
        font-weight: bold;
        display: flex;
        justify-content: center;
    }
}

@media(max-width: 375px) {
    .mmain {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        margin-left: 0px;
    }

    .mmain .mcontainerm {
        justify-content: center;
        flex-direction: unset;
    }

    .overall {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(155% - 15px);
        margin-left: -50px;
    }

    .mcard-subtitle p {
        font-size: 17px;

        font-family: Outfit;
        margin-top: 20px;
        font-weight: 100;
    }

    .mafter-img {
        font-family: 'Outfit';
        font-weight: 500;

    }

    .mrectangle-heading {
        font-family: Outfit;
        font-size: 25px;
        font-weight: bold;
        display: flex;
        justify-content: center;
    }
}